import { state } from './State'
import { Capsule, StoredCapsule } from './CapsuleClasses'
import { dumpStates, resetStates, objectMap, doLogout } from './Functions'

let mergedState = state
export const getMergedState = (sessionState, storedState) => {
  mergedState = {
    ...state,
    ...objectMap(sessionState, (key, value) => new Capsule(key, value)),
    ...objectMap(storedState, (key, value) => new StoredCapsule(key, value))
  }
  return mergedState
}

export default state
export { dumpStates, resetStates, doLogout, mergedState }
