import { Capsule, StoredCapsule } from './CapsuleClasses'
import { objectMap } from './Functions'

const sessionState = {
  typeSelected: '',
  messageGrowl: '',
  visibleGrowl: false,
  messageText: '',
  desktopNotificationVisible: false,
  newMessageConversation: ''
}

const storedState = {
  auth: {
    jwt: '',
    userId: '',
    userAgency: '',
    userFullName: '',
    codSociety: '',
    opUnit: ''
  },
  desktopNotificationActivated: false,
  config: {
    defaultTheme: 1,
    awsBaseURL: 'https://s3.eu-central-1.amazonaws.com/siap.apptour.uploads/',
    mediumScreen: 1240,
    smallScreen: 640
  }
}

export const state = {
  ...objectMap(sessionState, (key, value) => new Capsule(key, value)),
  ...objectMap(storedState, (key, value) => new StoredCapsule(key, value))
}
