import React from 'react'
import { Input } from '.'
import { useWindowSize } from 'react-use'
import state from '@Common/State'
import COUNTRIES from '@Common/Resources/json/countriesPrefixPriority.json'

const countryListDropDefault = COUNTRIES.map((elem, index) => ({
  label: elem.name,
  prefix: elem.dialCode,
  value: index,
  priority: elem.priority
}))
export const NumberPrefix = ({
  style,
  styleDrop,
  stylePrefix,
  disabledDrop,
  prefixCountry,
  onChangeSelectedCountry
}) => {
  const { width: screenWidth } = useWindowSize()
  const config = state.config.state
  return (
    <div style={{ display: 'flex', flexDirection: 'row', ...style }}>
      <Input
        disabled={disabledDrop} // !!client._id
        dropdown
        id='dropPrefix'
        style={{
          marginRight: 1,
          minWidth: 180,
          maxWidth: screenWidth > config.smallScreen ? 180 : '100%',
          marginTop: screenWidth > config.smallScreen ? 0 : 5,
          ...styleDrop
        }}
        options={countryListDropDefault}
        placeholder={prefixCountry.label}
        onChange={(e) => {
          const filt = countryListDropDefault.filter((item) => item.value === e.dropPrefix)
          onChangeSelectedCountry(filt[0])
        }}
      />
      <Input
        disabled
        id='prefix'
        style={{
          height: 35,
          marginRight: 1,
          width: 90,
          marginTop: screenWidth > config.smallScreen ? 0 : 5,
          ...stylePrefix
        }}
        placeholder={prefixCountry.prefix}
        value={prefixCountry.prefix || ''}
      />
    </div>
  )
}
