import * as Sentry from '@sentry/react'
import SentryRRWeb from '@sentry/rrweb'
import state, { mergedState } from '../State'

const enableSentryInDevelopment = false

const isDevelopment = import.meta.env.DEV
const environment = window?._env_?.ENVIRONMENT || 'development'

const getReportDialog = () => ({
  title: 'Oops, é accaduto un errore inaspettato',
  subtitle: 'Il problema é stato segnalato, ',
  subtitle2: 'potresti dirci qualcosa di più?',
  user: {
    email: state.auth.state.userId,
    name: state.auth.state.userFullName
  },
  successMessage: 'Grazie per aver inviato un feedback sul problema, lo correggeremo al più presto. Si prega di ricaricare la pagina.'

})

const initSentry = () => {
  isDevelopment && console.log('Sentry Init...')
  Sentry.init({
    dsn: 'https://61ae5a10ad0840049baed662c0d40a1e@o532565.ingest.sentry.io/6262253',
    integrations: [new SentryRRWeb({
      // ...options
    })],
    environment,
    beforeSend: (event, hint) => {
      if (event.exception) {
        const gState = {}
        Object.entries(mergedState).forEach(([key, value]) => { gState[key] = value.state })
        event.extra = { ...(event?.extra || {}), state: gState }
        Sentry.showReportDialog({
          eventId: event.event_id,
          ...getReportDialog()
        })
      }
      return event
    }
  })
  Sentry.setTag('rrweb.active', 'yes')
}

  ; (!isDevelopment || enableSentryInDevelopment) && initSentry()

export { getReportDialog }
