import { getMergedState } from '@Common/State'

const sessionState = {
  checkErrorsRequest: false,
  checkErrorsClient: false,
  checkErrorsProposal: false,
  showEmailModal: false,
  showDuplicateModal: false,
  startCreateAtlPra: '',
  crmUser: null,
  globalSearch: {},
  resetChanges: {},
  showContractsPage: false
}
const storedState = {
  quotes: {}
}

// Workaround, qualcosa nei common components invoca questo file
// const state = window.location.href.includes('/quotes') ? getMergedState(sessionState, storedState) : null

const state = getMergedState(sessionState, storedState)

export default state
