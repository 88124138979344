/* eslint-disable no-console */
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

let client = {}
const isDevelopment = !window?._env_?.ENVIRONMENT || window?._env_?.ENVIRONMENT === 'development'
const API_BASE_URL =
  window._env_ && window._env_.API_BASE_URL ? window._env_.API_BASE_URL : 'http://localhost:3000'

const doMethod = async (method, url, body, noTrace) => {
  try {
    const success = method === 'post' ? 'Created' : 'OK'
    const response = await client[method](url, body)
    response.ok = response.statusText === success
    url === '/authentication' && response.ok && Api.setJWT(response.data.accessToken)
    return response
  } catch (err) {
    return {
      ok: false,
      error: err
    }
  }
}

const isJWTValid = (jwt) => {
  try {
    return moment.unix(jwtDecode(jwt).exp) - moment() > 0
  } catch (e) { }
  return false
}

const getClient = (jwt) => {
  const jwtOK = isJWTValid(jwt)
  isDevelopment && console.log(`HTTP Api Initialized, JWT: ${jwtOK}, baseURL: ${API_BASE_URL}`)
  const headers = { 'Content-Type': 'application/json' }
  jwtOK && (headers.Authorization = jwt)
  return axios.create({ baseURL: API_BASE_URL, timeout: 60000, headers })
}

const Api = {
  jwt: '',
  setJWT: (jwt) => {
    Api.jwt = jwt
    client = getClient(jwt)
  },
  wait: (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
  get: async (url) => doMethod('get', url),
  post: async (url, body) => doMethod('post', url, body),
  patch: async (url, id, body) => doMethod('patch', `${url}/${id}`, body),
  delete: async (url, id) => doMethod('delete', `${url}/${id}`),
  login: async (phone, password) =>
    doMethod('post', '/authentication', { strategy: 'local', phone, password })
}

Api.setJWT(/* state && state.auth ? state.auth?.state?.jwt : */ '')

export { Api, isJWTValid }
