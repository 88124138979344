import { useState, useEffect } from 'react'
import axios from 'axios'
import { FC, getStringHash } from '@Common/Services'

const client = axios.create({ baseURL: window.location.origin, timeout: 10000 })

const CHECK_INTERVAL = 300 * 1000

export const Updater = () => {
  const [indexHash, setIndexHash] = useState(false)
  useEffect(() => {
    const checkUpdate = () => {
      client.get('/').then(({ data, status }) => {
        if (status !== 200) return false
        const dataHash = getStringHash(data)
        if (!FC.isDev && indexHash && indexHash !== dataHash) {
          if (new Date().getHours() === 3) {
            window.location.reload()
          }
        } else {
          indexHash !== dataHash && setIndexHash(dataHash)
        }
      }).catch((e) => console.log('Catch : ', e))
    }
    const checkTimer = setInterval(checkUpdate, CHECK_INTERVAL)
    return () => { checkTimer && clearInterval(checkTimer) }
  }, [indexHash])
  return null
}
