import { sha256 } from 'js-sha256'
import { FC, Api, invalidateQuery } from '.'
import state from '@Common/State'

export const mailHandler = async (data) => {
  if (!data?.error) {
    window?.growl?.show({
      severity: 'success',
      summary: 'Email',
      detail: 'Email di notifica inviata con successo'
    })
  } else {
    // cap.gifEmail.setState(false)

    window.growl.show({
      severity: 'error',
      summary: 'Errore email',
      detail: 'Errore nell\'invio. Riprovare'
    })
  }
}

// Handler for messages
export const messageHandler = async (message) => {
  const selectedConversation = window.location.pathname?.split('/')[window.location.pathname?.split('/').length - 1] || ''
  const agency = state.auth.state.userAgency
  const insideChat = window.location && window.location.pathname.includes('/messages/' + selectedConversation)
  const insideAll = window.location && window.location.pathname.includes('/messages/all')
  const [foundUser] = await FC.service('users').find({ query: { _id: message.user } })
  setTimeout(() => state.visibleGrowl.setState(false), 10000)
  if ((!insideChat && !insideAll) || ((selectedConversation !== message.conversationId) && insideChat)) {
    state.messageGrowl.setState('Hai un nuovo messaggio')
    state.visibleGrowl.setState(message.user !== agency)
    state.desktopNotificationVisible.setState(!!state.desktopNotificationActivated.state)
    state.messageText.setState(`${foundUser?.name || ''} ${foundUser?.lastName || ''}: ${message?.text}`)
    state.newMessageConversation.setState(message.conversationId)
    invalidateQuery(['conversations', 'newMessagesBadge', 'messages'])
    return
  }
  // We are chatting in the current conversation
  // se sono io ad inviare il messaggio, deve arrivare la notifica. mentre se lo ricevo mentre ho  la chat aperta non deve arrivare la notifica
  if (state.auth.state.userAgency !== message.user) {
    FC.client.service('conversations').patch(selectedConversation, { nextActor: 'none', newAgency: 0 })
      .then(() => invalidateQuery(['conversations', 'newMessagesBadge', 'messages']))
  } else {
    invalidateQuery(['conversations', 'messages'])
  }
  state.messageGrowl.setState('Hai un nuovo messaggio')
  state.visibleGrowl.setState(message.user !== agency)
  state.desktopNotificationVisible.setState(!!state.desktopNotificationActivated.state)
  state.messageText.setState(`${foundUser?.name || ''} ${foundUser?.lastName || ''}: ${message?.text}`)
  state.newMessageConversation.setState(message.conversationId)
}

export const loginHandler = async (data) => {
  await Api.wait(800)
  const { jwt = '' } = state.auth.state
  if (!jwt) return false
  if (!data.users || !data.maxUsers || data.maxUsers >= data.users.length) return true

  const accessHash = sha256(jwt)
  const iAmTooMany = data.users.find((usr) => usr.accessHash === accessHash && usr.isLast)

  if (!iAmTooMany) return true
  FC.logout()
  const otherUsers = data.users
    .filter((usr) => usr.accessHash !== accessHash)
    .map(({ userId, lastPing }) => ({ userId, lastPing }))

  window.location.href = `/tooManyUsers/?maxUsers=${data.maxUsers}&info=${encodeURI(
    JSON.stringify(otherUsers)
  )}`
}

export const eventsHandler = async (data) => {
  window.growl.show({
    severity: data.type,
    summary: data.details.errorTitle,
    detail: data.details.userMessage
  })
}

export const newQuotesHandler = async (data) => {
  if (data?.createdBy !== 'agency') {
    state.messageGrowl.setState('RICHIESTA ARRIVATA DA UN CLIENTE!!')
    state.visibleGrowl.setState(true)
  }
  invalidateQuery(['badges', 'quotes'])
}

export const patchedQuoteHandler = (data) => {
  state.messageGrowl.setState(`Il cliente ${data.request.clientName || ''} ${data.request.clientLastName || ''} ha confermato il preventivo`)
  state.visibleGrowl.setState(true)
  invalidateQuery(['badges', 'quotes', 'quote'])
}
