import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom'

export const getQuoteStatus = (agency, { statusProposal, expireAt, createdAt, atlPratica, proposalNumber }) => {
  if (atlPratica && atlPratica.length) return 'Pratica Generata'
  if (!statusProposal) return 'In lavorazione'
  if (statusProposal === 'confirmed') return 'Confermato'
  if (statusProposal === 'cancelled' || statusProposal === 'canceled') return 'Annullato'
  if (!createdAt && !expireAt) return 'In lavorazione'

  const expireDate = getQuoteExpiration(agency, { expireAt, createdAt })
  const daysToExpire = (new Date(expireDate).getTime() - new Date().getTime()) / 86400000
  const daysPreExpired = parseInt(agency?.notifySettings?.daysPreExpired || 4)
  const expiredProposal = daysToExpire < 0

  const label = statusProposal === 'delivered' ? 'Consegnato' : 'In lavorazione'

  if (expiredProposal) return `${label} - Scaduto`
  if (daysToExpire >= daysPreExpired) return statusProposal === 'delivered' ? 'Consegnato - Valido' : 'In lavorazione'
  return `${label} - In scadenza`
}

const getQuoteExpiration = (agency, { createdAt, expireAt }, format) => {
  if (!createdAt && !expireAt) return ''
  const createdAtDate = new Date(createdAt)
  const defaultExp = agency && agency.notifySettings ? parseInt(agency.notifySettings.daysPreExpired) - 1 : 0
  const expireDate = expireAt ? new Date(expireAt) : (createdAtDate.getDate() + (defaultExp || 1))
  return format ? expireDate.format(format) : expireDate
}

export const getUrlParams = (params) => {
  if (!params) return undefined
  const url = new URL(window.location.href)
  const search = Array.isArray(params) ? params : [params]
  const found = search.map((p) => url.searchParams.get(p))
  return found.length === 1 ? found[0] : found
}

export const getStringHash = (st) => {
  let hash = 0
  for (let i = 0; i < st.length; i++) {
    const character = st.charCodeAt(i)
    hash = ((hash << 5) - hash) + character
    hash = hash & hash // Convert to 32bit integer
  }
  return `${hash}`
}

export const isValidEmail = (email = '') => {
  // eslint-disable-next-line
  const emailRx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRx.test(email.trim().toLowerCase())
}

const APPTOUR = ['/offers', '/atlTour', '/appointments', '/messages', '/tours', '/clients', '/home']

export const useHandleNavigate = () => {
  const routerNavigate = useNavigate()

  const navigate = (page) => {
    const location = window.location.pathname === '/' ? '/home' : window.location.pathname
    const imOnAppTour = APPTOUR?.find(i => location?.includes(i)) // controllo se la pagina attuale è nel ramo di appTour
    const isThePageInAppTour = APPTOUR?.find(i => page?.includes(i)) // controllo se la pagina di destinazione è nel ramo di appTour
    const startPath = location?.split('/')[1] // prendo la prima parte dell'indirizzo (/quotes/list => quotes)
    const endPath = page?.split('/')[1] // prendo la prima parte dell'indirizzo (/quotes/list => quotes)
    const isTheSameRoot = startPath === endPath // controllo se le due parti coincidono per sapere se sono nello stesso progetto
    if ((imOnAppTour && isThePageInAppTour) || (isTheSameRoot)) {
      // se sono nel progetto apptour devo navigare sull'indirizzo completo (perchè apptour non ha basename)
      // se sono in un altro progetto devo togliere la parte iniziale perchè ho già il basename
      routerNavigate((imOnAppTour && isThePageInAppTour) ? page : `/${page.split('/')[page.split('/').length - 1]}`)
    } else {
      window.location.href = page
    }
  }

  return navigate
}

export const checkRoute = ({ privacy = true, license, admin, hasAtl, page, content, ...props }, agency, user) => {
  const CustomNavigator = ({ to }) => {
    const navigate = useHandleNavigate()
    navigate(to)
    return null
  }

  const hasLicense = (license) => (agency.services ? agency.services[license] : false)
  const hasNoPrivacy = (!agency.privacyList || agency.privacyList.general === '') && !agency.privacyDoc
  const isAdmin = user.security === 'admin' || user.networkAdmin
  const { app, chat, offers, quotes } = agency.services

  let redirect = false
  privacy && hasNoPrivacy && (redirect = '/settings/privacy')
  license && !hasLicense(license) && (redirect = '/')
  admin && !isAdmin && (redirect = '/')
  hasAtl && !agency.hasAtl && (redirect = '/')
  page === 'Home' && !app && !chat && !offers && quotes && (redirect = '/quotes/')
  page === 'Home' && isAdmin && (redirect = '/administration/stats')
  return redirect
    ? <Route path='*' element={<CustomNavigator to={redirect} />} />
    : content
}

export const CheckRegex = ({ children, regex, ifNotExistsNavigate = '/' }) => {
  const pathname = useLocation()?.pathname
  const location = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const regexToMatch = new RegExp(regex)
  return regexToMatch.test(location) ? children : <Navigate to={ifNotExistsNavigate} />
}

export const readFile = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    if (!file) return null
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const translateWeekDays = (day) => {
  day._locale._weekdaysMin = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
  return day.format('D')
}

export const months = { January: 'Gennaio', February: 'Febbraio', March: 'Marzo', April: 'Aprile', May: 'Maggio', June: 'Giugno', July: 'Luglio', August: 'Agosto', September: 'Settembre', October: 'Ottobre', November: 'Novembre', December: 'Dicembre' }
